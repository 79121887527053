<template>
    <section>
        <modal ref="ActivarTienda" titulo="Activar Vendedor" icon="check" @guardar="cambiar_estado">
            <div class="row mx-0 justify-center mb-3">
                <div class="col">
                    <p class="text-muted2 text-center f-15">
                        ¿Desea poner el producción la tienda?
                    </p>
                </div>
            </div>
        </modal>
    </section>
</template>
<script>
import CambioEstado from "../../../../services/tiendas/cambio_estados";

export default {
    data(){
        return{
            model: {
                id: null,
                id_motivo: null,
                justificacion: null,
                tipo:5
            },
        }
    },
    methods: {
        toggle(){
            this.$refs.ActivarTienda.toggle();
            this.model.id = null
        },
        async cambiar_estado(){
            try {
                this.model.id = this.$route.params.id_tienda
                const {data} = await CambioEstado.cambiar_estado(this.model)
                this.notificacion('Mensaje','Vendedor activada correctamente','success')
                this.$refs.ActivarTienda.toggle();
                this.$router.push({ name: 'admin.tiendas.produccion.informacion', params: { id_tienda: this.$route.params.id_tienda } })
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
